import React from "react";
import classNames from "classnames";

import "./DragOverlay.scss";

interface DragOverlayProps {
  isVisible: boolean;
}
export const DragOverlay: React.FC<DragOverlayProps> = ({ isVisible }) => {
  return (
    <div className={classNames("dragOverlay", { isVisible })}>
      <p className="dragOverlayText">Drag to upload video or audio file</p>
    </div>
  );
};
