import * as THREE from 'three';

export function getLocation(): Promise<{ lat: number, lon: number } | undefined> {
    if (navigator.geolocation) {
        return new Promise((res) =>
            navigator.geolocation.getCurrentPosition(
                (position) => res({ lat: position.coords.latitude, lon: position.coords.longitude }),
                (e) => {
                    console.warn("Could not get geoposition", e);
                    res(undefined);
                }
            )
        )
    }
    return Promise.resolve(undefined)
}

export function getPointOnSphere(lat: number, lon: number, radius: number, vec = new THREE.Vector3()) {
    let phi = (90 - lat) * (Math.PI / 180),
        theta = (lon + 180) * (Math.PI / 180),
        x = -((radius) * Math.sin(phi) * Math.cos(theta)),
        z = ((radius) * Math.sin(phi) * Math.sin(theta)),
        y = ((radius) * Math.cos(phi));
    vec.set(x, y, z);
    return vec;
}

export function addNoise(loc: { lat: number, lon: number }, latNoiseAmplitude = 3, lonNoiseAmplitude = 6) {
    let latNoise = (Math.random() * 2 - 1) * latNoiseAmplitude;
    let lonNoise = (Math.random() * 2 - 1) * lonNoiseAmplitude;
    let lat = Math.max(-90, Math.min(90, loc.lat + latNoise));
    let lon = loc.lon + lonNoise;
    if (lon < -180) lon += 360;
    if (lon > 180) lon -= 360;
    return { ...loc, lat, lon };
}