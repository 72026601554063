import React from "react";

import "./AudioUpload.scss";

interface AudioUploadProps {
  onUpload: (file: File) => void;
}
export const AudioUpload: React.FC<AudioUploadProps> = ({ onUpload }) => {
  return (
    <div className="audioUpload">
      <div className="audioUploadLabel">
        Add your videos or
        <br /> audio files
      </div>
      <label className="audioUploadInputLabel">
        <input
          className="audioUploadInput"
          type="file"
          accept="video/mp4,video/x-m4v,video/*,audio/x-m4a,audio/*"
          onChange={(evt) =>
            evt.currentTarget.files?.length &&
            onUpload(evt.currentTarget.files[0])
          }
        />
      </label>
    </div>
  );
};
