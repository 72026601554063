import React, { useRef, useMemo, useEffect, useCallback } from "react";
import { range } from "lodash";

import {
  ANALYSER_NUM_SPECTRAL_FREQUENCIES,
  ANALYSER_SMOOTHING,
  MAX_SOUND_DURATION,
} from "./constants";
import { SoundRecording } from "./sound/SoundRecorder";
import { audioContext } from "./sound/audio";

import "./Waveform.scss";
import { SoundRecordingPlayer } from "./sound/SoundRecordingPlayer";

const BAR_COUNT = 200;
const MAX_DB = -75;
const CANVAS_MARGIN = 10;

interface WaveformProps {
  recording: SoundRecording;
  player?: SoundRecordingPlayer;
}
export const Waveform: React.FC<WaveformProps> = ({ recording, player }) => {
  let parentRef = useRef<HTMLDivElement | null>(null);
  let canvasRef = useRef<HTMLCanvasElement | null>(null);
  let playheadRef = useRef<HTMLDivElement | null>(null);

  let volBins = useMemo(
    () => range(BAR_COUNT).map(() => ({ total: 0, count: 0 })),
    []
  );

  let renderBin = useCallback(
    (bin: number, clear: boolean) => {
      let width = parentRef.current!.offsetWidth * window.devicePixelRatio;
      let height = parentRef.current!.offsetHeight * window.devicePixelRatio;

      let ctx = canvasRef.current!.getContext("2d")!;
      if (clear) {
        ctx.clearRect(0, 0, width, height);
        canvasRef.current!.width = width;
        canvasRef.current!.height = height;
      }

      // Render volume bars
      ctx.lineWidth = 1;
      ctx.strokeStyle = "white";
      let barWidth = (width - 2 * CANVAS_MARGIN) / BAR_COUNT;
      let barHeight = height - 2 * CANVAS_MARGIN;
      ctx.beginPath();
      let x = Math.round(CANVAS_MARGIN + bin * barWidth);
      let avgDb =
        volBins[bin].count > 0
          ? volBins[bin].total / volBins[bin].count
          : MAX_DB;
      let relDb = Math.max(0, Math.min(1, 1 - avgDb / MAX_DB));
      ctx.moveTo(x, Math.round(CANVAS_MARGIN + (1 - relDb) * barHeight));
      ctx.lineTo(x, CANVAS_MARGIN + barHeight);
      ctx.stroke();
    },
    [recording, volBins]
  );

  useEffect(() => {
    if (recording.state === "recording") {
      volBins.forEach((bin) => {
        bin.total = 0;
        bin.count = 0;
      });

      let analyser = audioContext.createAnalyser();
      analyser.fftSize = ANALYSER_NUM_SPECTRAL_FREQUENCIES * 2;
      analyser.smoothingTimeConstant = ANALYSER_SMOOTHING;
      recording.recorder.analyse(analyser);

      let sampleBuffer = new Float32Array(analyser.fftSize);

      let running = true,
        first = true;
      let analyseFrame = () => {
        if (!running) return;
        analyser.getFloatTimeDomainData(sampleBuffer);
        let peakInstantaneousPower = 0;
        for (let i = 0; i < sampleBuffer.length; i++) {
          let power = sampleBuffer[i] ** 2;
          peakInstantaneousPower = Math.max(power, peakInstantaneousPower);
        }
        let peakInstantaneousPowerDecibels =
          10 * Math.log10(peakInstantaneousPower);

        let progress = recording.recorder.recordedDuration / MAX_SOUND_DURATION;
        let bin = Math.min(BAR_COUNT - 1, Math.floor(progress * BAR_COUNT));
        volBins[bin].total += peakInstantaneousPowerDecibels;
        volBins[bin].count++;

        renderBin(bin, first);
        playheadRef.current!.style.transform = `translateX(${
          CANVAS_MARGIN +
          progress * (parentRef.current!.offsetWidth - 2 * CANVAS_MARGIN)
        }px)`;

        requestAnimationFrame(analyseFrame);
        first = false;
      };
      requestAnimationFrame(analyseFrame);

      return () => {
        running = false;
      };
    } else if (player) {
      let running = true;
      let playerFrame = () => {
        if (!running) return;
        let progress = player.playedDuration / MAX_SOUND_DURATION;
        playheadRef.current!.style.transform = `translateX(${
          CANVAS_MARGIN +
          progress * (parentRef.current!.offsetWidth - 2 * CANVAS_MARGIN)
        }px)`;
        requestAnimationFrame(playerFrame);
      };
      requestAnimationFrame(playerFrame);
      return () => {
        running = false;
      };
    }
  }, [recording, player, volBins, renderBin]);

  return (
    <div className="waveform" ref={parentRef}>
      <canvas ref={canvasRef}></canvas>
      <div className="playhead" ref={playheadRef} />
    </div>
  );
};
