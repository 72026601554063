import React, { useState } from "react";
import classNames from "classnames";

import "./AboutContent.scss";

interface AboutContentProps {
  isVisible: boolean;
  onSetVisible: (visible: boolean) => void;
}

export const AboutContent: React.FC<AboutContentProps> = ({
  isVisible,
  onSetVisible,
}) => {
  return (
    <div className={classNames("aboutContent", { isVisible })}>
      <div className="aboutContentText">
        <p>
          In this moment of tremendous change and uncertainty, the Dallas Museum
          of Art in collaboration with experience and sound designer Yuri Suzuki
          wanted to create an open platform for people to express themselves and
          to capture our shared experience of the fleeting moments around us
          during this period. Through our collective observations and the simple
          act of listening, we hope to provide participants with a moment of
          global shared empathy and a means of connection. While physical
          isolation is often necessary in this current moment, connection is
          more important than ever.
        </p>
        <p>
          We invite you to submit sounds&mdash;from cooking dinner at home, to
          the ambulance siren passing by, to online connections with loved
          ones—and build a record of this global moment with us. The sounds will
          be mapped onto a virtual rendering of the globe based on the location
          in which they were captured, culminating in Sound of the Earth: The
          Pandemic Chapter&mdash;a dynamic audio experience of the lives of
          people around the globe.
        </p>
      </div>
      <button
        onClick={() => onSetVisible(isVisible ? false : true)}
        className="aboutContentCloseButton"
      >
        <span className="sr-only">Close</span>
        <svg
          className="iconClose"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 29.1 29.1"
        >
          <line x1="28.1" y1="28.1" x2="1" y2="1" />
          <line x1="1" y1="28.1" x2="28.1" y2="1" />
        </svg>
      </button>
    </div>
  );
};
