import EventEmitter from 'events';

export class SoundRecordingPlayer extends EventEmitter {
    private audioEl?: HTMLAudioElement

    constructor(private recording: Blob) {
        super();
    }

    start() {
        this.audioEl = document.createElement('audio');
        this.audioEl.addEventListener('ended', () => this.emit('stopped'));
        this.audioEl.src = URL.createObjectURL(this.recording);
        this.audioEl.play();
    }

    stop() {
        if (this.audioEl) {
            this.audioEl.pause();
            this.audioEl.removeAttribute('src');
            this.audioEl.load();
            this.audioEl = undefined;
            this.emit('stopped');
        }
    }

    get playedDuration() {
        if (this.audioEl) {
            return this.audioEl.currentTime;
        } else {
            return 0;
        }
    }
}