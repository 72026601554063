import React, { useState, useCallback } from "react";
import classNames from "classnames";
import { IAudioContext } from "standardized-audio-context";

import "./AudioToggle.scss";

interface AudioToggleProps {
  gain: GainNode;
  audioCtx: IAudioContext;
}
export const AudioToggle: React.FC<AudioToggleProps> = ({ gain, audioCtx }) => {
  let [isMuted, setIsMuted] = useState(false);

  let updateMuted = useCallback(
    (newMuted: boolean) => {
      if (newMuted) {
        gain.gain.setValueAtTime(1, audioCtx.currentTime);
        gain.gain.linearRampToValueAtTime(0, audioCtx.currentTime + 0.33);
      } else {
        gain.gain.setValueAtTime(0, audioCtx.currentTime);
        gain.gain.linearRampToValueAtTime(1, audioCtx.currentTime + 0.33);
      }
      setIsMuted(newMuted);
    },
    [gain, audioCtx]
  );

  return (
    <div className="audioToggle">
      <button
        className={classNames("audioToggleButton audioToggleButton--mute", {
          isVisible: !isMuted,
        })}
        onClick={() => updateMuted(isMuted ? false : true)}
      >
        <span className="sr-only">Mute audio</span>
        <svg viewBox="0 0 30 26">
          <path d="M0,7V19H6l8,7V0L6,7ZM5,17H2V9H5ZM7,8.78l5-4.37V21.59L7,17.22ZM22,13a7,7,0,0,0-4-6.32L16.83,8.36a5,5,0,0,1,0,9.28L18,19.32A7,7,0,0,0,22,13ZM22.57.07,21.43,1.71a13,13,0,0,1,0,22.58l1.14,1.64a15,15,0,0,0,0-25.86ZM26,13a11,11,0,0,0-5.71-9.64L19.14,5a9,9,0,0,1,0,16l1.15,1.66A11,11,0,0,0,26,13Z" />
        </svg>
      </button>
      <button
        className={classNames("audioToggleButton audioToggleButton--play", {
          isVisible: isMuted,
        })}
        onClick={() => updateMuted(isMuted ? false : true)}
      >
        <span className="sr-only">Play audio</span>
        <svg viewBox="0 0 30 26">
          <path d="M0,7V19H6l8,7V0L6,7ZM5,17H2V9H5ZM7,8.78l5-4.37V21.59L7,17.22ZM30,8.4,28.6,7,24,11.6,19.4,7,18,8.4,22.6,13,18,17.6,19.4,19,24,14.4,28.6,19,30,17.6,25.4,13Z" />
        </svg>
      </button>
    </div>
  );
};
