import { IAudioNode, AudioContext } from 'standardized-audio-context';
import * as THREE from 'three';
import * as gps from '../gps';

import { createAudioSource, audioContext } from "./audio";
import { MEDIA_BASE_URL } from "../constants";
import { getPointOnSphere } from '../gps';

export type AudioSource = { node: IAudioNode<AudioContext>, release: () => void };;
export type Location = { lat: number, lon: number };

const FADE_OUT_TIME = 2;

export class Sound {
    public location: Location;
    private playing = false;
    private currentPlayer: AudioSource | null = null;
    private currentPos: THREE.PositionalAudio | null = null;
    private gain = audioContext.createGain();
    private fadingOutSince = 0;

    constructor(private file: string, location: Location) {
        this.location = gps.addNoise(location);
    }

    async play(worldRadius: number, listener: THREE.AudioListener) {
        if (this.playing) return;
        this.playing = true;
        this.currentPos = new THREE.PositionalAudio(listener);
        this.getPointOnSphere(worldRadius, this.currentPos.position);
        this.currentPos.setNodeSource(this.gain as any);
        this.currentPos.updateMatrixWorld();
        let player = await createAudioSource(`${MEDIA_BASE_URL}/media/live/${this.file}`, this.gain);
        if (this.playing) {
            this.currentPlayer = player;
            this.gain.gain.linearRampToValueAtTime(1, audioContext.currentTime + 0.02);
        } else {
            player.release();
        }
    }

    stop() {
        if (this.playing) {
            let player = this.currentPlayer;
            let pos = this.currentPos;
            this.gain.gain.setValueAtTime(1, audioContext.currentTime);
            this.gain.gain.linearRampToValueAtTime(0, audioContext.currentTime
                + FADE_OUT_TIME);
            setTimeout(() => {
                pos?.disconnect();
                player?.release();
            }, FADE_OUT_TIME * 1000);
            this.currentPlayer = null;
            this.currentPos = null;
            this.playing = false;
            this.fadingOutSince = Date.now();
        }
    }

    getPointOnSphere(radius: number, vec = new THREE.Vector3()) {
        return getPointOnSphere(this.location.lat, this.location.lon, radius, vec);
    }

    getCurrentVol() {
        if (this.playing) {
            return 1;
        } else {
            let fadingOutFor = Date.now() - this.fadingOutSince;
            let fadingOutRel = fadingOutFor / (FADE_OUT_TIME * 1000);
            return 1 - Math.max(0, Math.min(1, fadingOutRel));
        }
    }
}