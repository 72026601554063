import EventEmitter from 'events';
import axios from 'axios';

import { MEDIA_BASE_URL } from "./constants";
import { Sound } from "./sound/Sound";
import * as gps from './gps';

export function loadSounds(): Promise<Sound[]> {
    return fetch(`${MEDIA_BASE_URL}/media/live/index.json`)
        .then((res) => res.json())
        .then((res) =>
            res.map((data: any) => new Sound(data.file, data.location))
        )
}

export function uploadSound(sound: Blob, source: 'app' | 'file', useDeviceLocation: boolean) {
    return new Upload(sound, source, useDeviceLocation);
}

export class Upload extends EventEmitter {

    constructor(private sound: Blob, private source: 'app' | 'file', private useDevicelocation: boolean) {
        super();
        this.doUpload();
    }

    private async doUpload() {
        let form = new FormData();
        form.append('sound', this.sound);
        form.append('source', this.source);
        if (this.useDevicelocation) {
            let loc = await gps.getLocation();
            if (loc) {
                form.append('lat', '' + loc.lat);
                form.append('lon', '' + loc.lon);
            }
        }
        try {
            await axios.post(`${MEDIA_BASE_URL}/submissions`, form, {
                onUploadProgress: (evt) => {
                    this.emit('progress', evt.loaded / evt.total);
                }
            });
            setTimeout(() => {
                this.emit('done');
            }, 5000);
        } catch (e) {
            console.error(e);
            this.emit('error');
            setTimeout(() => {
                this.emit('done');
            }, 5000);
        }
    }
}