import React, { useMemo, useEffect, useRef, useState } from "react";
import * as THREE from "three";
import CameraControls from "camera-controls";
import { useThree, useFrame, useLoader } from "react-three-fiber";
import { takeRight, last } from "lodash";
import { useThrottledFn } from "beautiful-react-hooks";

import { Sound } from "./sound/Sound";
import * as audio from "./sound/audio";
import HaloImg from "./images/halo.png";
import {
  getJumpGeometry,
  jumpShaderMaterial,
  updateJumpGeometry,
} from "./jumpSplines";
import { SoundPoint } from "./SoundPoint";

const EARTH_RADIUS = 2.5;

interface EarthProps {
  sounds: Sound[];
  cameraControls: CameraControls;
  audioListener: THREE.AudioListener;
  isInteractive: boolean;
}
export const Earth: React.FC<EarthProps> = ({
  sounds,
  cameraControls,
  audioListener,
  isInteractive,
}) => {
  let { camera, gl, mouse } = useThree();
  let [interacted, setInteracted] = useState(false);
  let soundPointsGeometry = useMemo(() => {
    let pointArray = new Float32Array(sounds.length * 3);
    for (let i = 0; i < sounds.length; i++) {
      pointArray.set(
        sounds[i].getPointOnSphere(EARTH_RADIUS + 0.0001).toArray(),
        i * 3
      );
    }
    let geometry = new THREE.BufferGeometry();
    geometry.setAttribute("position", new THREE.BufferAttribute(pointArray, 3));
    return geometry;
  }, [sounds]);
  let raycaster = useMemo(() => {
    let raycaster = new THREE.Raycaster();
    raycaster.params.Points = { threshold: 0.2 };
    return raycaster;
  }, []);
  let soundPointsRef = useRef<THREE.Points>();
  let [playingSoundIndexes, setPlayingSoundIndexes] = useState<number[]>([]);
  let [jumpGeometries, setJumpGeometries] = useState<THREE.BufferGeometry[]>(
    []
  );

  let playSound = (useThrottledFn(
    (index: number) => {
      if (last(playingSoundIndexes) === index) return;
      if (playingSoundIndexes.length > 0) {
        let lastPlayingIndex = last(playingSoundIndexes)!;
        sounds[lastPlayingIndex].stop();
        setJumpGeometries((g) => {
          let newJumpGeometry = getJumpGeometry(
            sounds[lastPlayingIndex],
            sounds[index],
            EARTH_RADIUS,
            last(g)?.userData.startAgingAt
          );
          return [...takeRight(g, 20), newJumpGeometry];
        });
      }
      sounds[index].play(2, audioListener);
      setPlayingSoundIndexes([...takeRight(playingSoundIndexes, 20), index]);
    },
    300,
    undefined,
    [sounds, playingSoundIndexes, audioListener]
  ) as any) as (idx: number) => void;
  useEffect(() => {
    if (!isInteractive) {
      sounds.forEach((s) => s.stop());
    }
  }, [isInteractive]);

  let earthRef = useRef<THREE.Mesh>();

  useFrame((state, delta) => {
    cameraControls.minZoom = EARTH_RADIUS - 2;
    cameraControls.maxZoom = EARTH_RADIUS * 10;

    cameraControls.mouseButtons.left = CameraControls.ACTION.ROTATE;
    cameraControls.mouseButtons.middle = CameraControls.ACTION.ZOOM;
    cameraControls.mouseButtons.right = CameraControls.ACTION.ZOOM;
    cameraControls.mouseButtons.wheel = CameraControls.ACTION.ZOOM;
    cameraControls.touches.one = CameraControls.ACTION.TOUCH_ROTATE;
    cameraControls.touches.two = CameraControls.ACTION.TOUCH_ZOOM;
    cameraControls.touches.three = CameraControls.ACTION.TOUCH_ZOOM;

    if (!interacted) cameraControls.rotate(0.04 * delta, 0, false);
    cameraControls.update(delta);

    if (isInteractive) {
      raycaster.setFromCamera(mouse, camera);
      let pointIntersections = raycaster.intersectObjects([
        earthRef.current!,
        soundPointsRef.current!,
      ]);
      if (
        pointIntersections.length > 0 &&
        pointIntersections[0].object === soundPointsRef.current!
      ) {
        let intersection = pointIntersections[0];
        playSound(intersection.index!);
      }
    }

    for (let jumpGeom of jumpGeometries) {
      updateJumpGeometry(jumpGeom);
    }

    audio.updateConvolver(
      (camera.zoom - cameraControls.minZoom) /
        (cameraControls.maxZoom - cameraControls.minZoom)
    );
  });

  let halo = useLoader(THREE.TextureLoader, HaloImg);

  return (
    <>
      <camera>
        <ambientLight intensity={0.5} />
        <primitive object={camera}>
          {/* <directionalLight position={[100, 100, 100]} intensity={0.05} /> */}
        </primitive>
      </camera>
      <mesh ref={earthRef} onPointerDown={() => setInteracted(true)}>
        <sphereBufferGeometry attach="geometry" args={[EARTH_RADIUS, 48, 48]} />
        <meshPhongMaterial attach="material" color="#111111" />
        <sprite scale={[7, 7, 0]}>
          <spriteMaterial attach="material" map={halo} />
        </sprite>
      </mesh>
      <points geometry={soundPointsGeometry} ref={soundPointsRef}>
        <pointsMaterial
          attach="material"
          color={0x808080}
          size={3}
          sizeAttenuation={false}
        />
      </points>
      <group renderOrder={1}>
        {playingSoundIndexes.map((soundIndex, idx) => (
          <SoundPoint
            key={idx}
            sound={sounds[soundIndex]}
            earthRadius={EARTH_RADIUS}
          />
        ))}
      </group>
      <group>
        {jumpGeometries.map((jumpGeom, idx) => (
          <line
            // @ts-ignore
            geometry={jumpGeom}
            material={jumpShaderMaterial}
            key={idx}
          />
        ))}
      </group>
    </>
  );
};
