import { AudioContext, IAudioNode } from 'standardized-audio-context';
import * as THREE from 'three';
import irUrl from './ir.mp3';

let audioCtx: AudioContext;
try {
    audioCtx = new AudioContext({ sampleRate: 44100 });
} catch (e) {
    audioCtx = new AudioContext();
}
export const audioContext = audioCtx;
(THREE.AudioContext as any).setContext(audioContext);

let convolver = audioContext.createConvolver();
let convolverWet = audioContext.createGain();
let convolverDry = audioContext.createGain();
convolverWet.connect(convolver);
convolver.connect(audioContext.destination);
convolverDry.connect(audioContext.destination);

fetch(irUrl)
    .then(res => res.arrayBuffer())
    .then(arr => audioContext.decodeAudioData(arr))
    .then(buf => {
        convolver.buffer = buf;
    })

export function init() {
    audioContext.resume();
}

let audioElsSupported = true;
export async function createAudioSource(url: string, dest: IAudioNode<AudioContext>) {
    if (audioElsSupported) {
        try {
            return await createAudioElPlayer(url, dest);
        } catch (e) {
            console.log("Cannot autoplay audio elements. Falling back to buffers.")
            audioElsSupported = false;
            return createBufferSourcePlayer(url, dest);
        }
    } else {
        return createBufferSourcePlayer(url, dest);
    }
}

async function createAudioElPlayer(url: string, dest: IAudioNode<AudioContext>) {
    let audioEl = document.createElement('audio');
    audioEl.crossOrigin = 'anonymous';
    let src = audioContext.createMediaElementSource(audioEl);
    src.connect(dest);
    audioEl.src = url;
    await audioEl.play();
    return {
        node: src,
        release: () => {
            src.disconnect();
            audioEl.pause();
            audioEl.removeAttribute('src');
            audioEl.load();
        }
    };
}

async function createBufferSourcePlayer(url: string, dest: IAudioNode<AudioContext>) {
    let audioBuf = await fetch(url).then(res => res.arrayBuffer()).then(aBuf => audioContext.decodeAudioData(aBuf));
    let src = audioContext.createBufferSource();
    src.buffer = audioBuf;
    src.connect(dest);
    src.start();
    return {
        node: src,
        release: () => {
            src.stop();
            src.disconnect();
        }
    };
}

export function getMicrophoneStream(): Promise<MediaStream> {
    if (navigator.mediaDevices) {
        return navigator.mediaDevices.getUserMedia({
            audio: true,
            video: false
        });
    } else {
        return new Promise((res, rej) =>
            navigator.getUserMedia({ audio: true, video: false }, res, rej)
        );
    }
}

export function makeAudioListener() {
    let listener = new THREE.AudioListener();
    listener.gain.disconnect();
    listener.gain.connect(convolverWet as any);
    listener.gain.connect(convolverDry as any);
    return listener;
}

export function updateConvolver(zoomLevel: number) {
    let conv = 1 - zoomLevel ** 0.3;
    convolverWet.gain.setTargetAtTime(Math.max(conv, 0.01), audioContext.currentTime, 0.01);
    convolverDry.gain.setTargetAtTime(Math.max(1 - conv, 0.01), audioContext.currentTime, 0.01);
}