import React, { useMemo, useRef } from "react";
import { Sound } from "./sound/Sound";
import { Mesh, MeshBasicMaterial } from "three";
import { useFrame } from "react-three-fiber";

const OSCILLATION_FREQUENCY = 4;
const OSCILLATION_AMPLITUDE = 0.1;

interface SoundPointProps {
  sound: Sound;
  earthRadius: number;
}
export const SoundPoint: React.FC<SoundPointProps> = ({
  sound,
  earthRadius,
}) => {
  let meshRef = useRef<Mesh>();
  let pos = useMemo(() => sound.getPointOnSphere(earthRadius), [
    sound,
    earthRadius,
  ]);

  useFrame(({ clock }) => {
    let mesh = meshRef.current!;
    let scale =
      1 +
      OSCILLATION_AMPLITUDE *
        Math.sin(clock.elapsedTime * OSCILLATION_FREQUENCY);
    mesh.scale.set(scale, scale, scale);

    (mesh.material as MeshBasicMaterial).opacity = sound.getCurrentVol();
  });

  return (
    <mesh position={pos} ref={meshRef}>
      <sphereBufferGeometry attach="geometry" args={[0.02, 16, 16]} />
      <meshBasicMaterial attach="material" color="#b3b3b3" transparent={true} />
    </mesh>
  );
};
