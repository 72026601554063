import EventEmitter from 'events';
import { AudioContext, IMediaStreamAudioSourceNode, IAnalyserNode } from 'standardized-audio-context';
import { isNumber } from 'lodash';

import { MAX_SOUND_DURATION } from '../constants';

export type SoundRecording = {
    state: 'recording' | 'done',
    recorder: SoundRecorder,
    recording?: Blob
}

export class SoundRecorder extends EventEmitter {
    private streamSource?: IMediaStreamAudioSourceNode<AudioContext>;
    private recorder?: MediaRecorder;
    private chunks?: Blob[];
    private recordingStartedAt?: number;
    private stopper?: NodeJS.Timeout;

    constructor(
        private audioCtx: AudioContext,
        private inStream: MediaStream
    ) {
        super();
    }

    start() {
        this.recorder = new MediaRecorder(this.inStream);
        this.chunks = [];
        this.recorder.addEventListener("dataavailable", (evt) =>
            this.chunks!.push((evt as BlobEvent).data)
        );
        this.recorder.addEventListener("start", () =>
            this.emit("start")
        );
        this.streamSource = this.audioCtx.createMediaStreamSource(this.inStream);
        (this.recorder as MediaRecorder).start();
        this.recordingStartedAt = this.audioCtx.currentTime;
        this.stopper = setTimeout(() => {
            this.stop();
        }, MAX_SOUND_DURATION * 1000);
    }

    analyse(analyser: IAnalyserNode<AudioContext>) {
        this.streamSource!.connect(analyser);
    }

    stop() {
        this.recorder!.addEventListener("stop", async () => {
            let sound = new Blob(this.chunks, { type: this.chunks![0].type });
            this.recorder = undefined;
            this.chunks = undefined;
            this.recordingStartedAt = undefined;
            this.stopper = undefined;
            this.streamSource?.disconnect();
            this.emit('recorded', sound);
        });
        this.recorder!.stop();
        for (let track of this.inStream.getAudioTracks()) {
            track.stop();
        }
        clearTimeout(this.stopper as NodeJS.Timeout);
    }

    get recordedDuration() {
        if (isNumber(this.recordingStartedAt)) {
            return this.audioCtx.currentTime - this.recordingStartedAt;
        } else {
            return 0;
        }
    }
}
