import React from "react";

import "./ZoomControls.scss";

interface ZoomControlProps {
  onZoomIn: () => void;
  onZoomOut: () => void;
}

export const ZoomControls: React.FC<ZoomControlProps> = ({
  onZoomIn,
  onZoomOut,
}) => {
  return (
    <div className="zoomControls">
      <button className="zoomControl zoomControl--in" onClick={onZoomIn}>
        <span className="sr-only">Zoom in</span>
        <svg viewBox="0 0 19.68 19.68">
          <line x1="19.68" y1="9.84" y2="9.84" />
          <line x1="9.84" y1="19.68" x2="9.84" />
        </svg>
      </button>
      <button className="zoomControl zoomControl--out" onClick={onZoomOut}>
        <span className="sr-only">Zoom out</span>
        <svg viewBox="0 0 19.68 19.68">
          <line x1="19.68" y1="9.84" y2="9.84" />
        </svg>
      </button>
    </div>
  );
};
