import React, { useCallback, useState } from "react";
import classNames from "classnames";

import "./Intro.scss";

interface IntroProps {
  isVisible: boolean;
  onDismiss: () => void;
}
export const Intro: React.FC<IntroProps> = ({ isVisible, onDismiss }) => {
  let [dismissed, setDismissed] = useState(false);
  let dismiss = useCallback(() => {
    setDismissed(true);
    setTimeout(() => onDismiss(), 1500);
  }, [onDismiss]);

  return (
    <div
      className={classNames("intro", { isVisible: !dismissed })}
      onClick={dismiss}
    >
      <div className="introContent">
        <h1 className="introHeading">
          Sound of the Earth:
          <br className="mobileOnly" /> the pandemic chapter
        </h1>
        <div className="introText">
          <p>
            The DMA, in collaboration with sound artist and experience designer
            Yuri Suzuki, presents an interactive artwork from sounds captured
            around the world during this time of isolation.
          </p>
          <p className="enterText enterText--mobile">
            Please enable audio, and click to begin.
          </p>
          <p className="enterText enterText--desktop">Click to begin.</p>
        </div>
      </div>
    </div>
  );
};
