import React, { useState, useCallback } from "react";
import { SoundRecording } from "./sound/SoundRecorder";
import { SoundRecordingPlayer } from "./sound/SoundRecordingPlayer";
import { Waveform } from "./Waveform";

import "./AudioRecording.scss";

interface AudioRecordingProps {
  recording: SoundRecording;
  onUploadRecording: () => void;
  onClearRecording: () => void;
}
export const AudioRecording: React.FC<AudioRecordingProps> = ({
  recording,
  onUploadRecording,
  onClearRecording,
}) => {
  let [player, setPlayer] = useState<SoundRecordingPlayer>();

  let play = useCallback(() => {
    let player = new SoundRecordingPlayer(recording.recording!);
    player.start();
    player.on("stopped", () => setPlayer(undefined));
    setPlayer(player);
  }, [recording]);

  let stop = useCallback(() => {
    player?.stop();
  }, [player]);

  return (
    <div className="audioRecording">
      <div className="audioRecordingText">Recording</div>
      <div className="audioRecordingPlayback">
        <div className="audioRecordingPlayPause">
          {recording.state === "done" && !player && (
            <button
              className="playPauseButton playPauseButton--play"
              onClick={play}
            >
              <span className="sr-only">Play</span>
              <svg className="iconPlay" viewBox="0 0 43.3 50">
                <polygon points="43.3 25 0 0 0 50 43.3 25" />
              </svg>
            </button>
          )}
          {recording.state === "done" && player && (
            <button
              className="playPauseButton playPauseButton--pause"
              onClick={stop}
            >
              <span className="sr-only">Pause</span>
              <svg className="iconPause" viewBox="0 0 35 50">
                <line x1="3" x2="3" y2="50" />
                <line x1="32" x2="32" y2="50" />
              </svg>
            </button>
          )}
        </div>
        <div className="audioRecordingWaveform">
          <Waveform recording={recording} player={player} />
        </div>
      </div>
      {recording.state === "done" && (
        <div className="audioRecordingActions">
          <button
            className="actionButton actionButton--upload"
            onClick={onUploadRecording}
          >
            Upload
          </button>
          <button
            className="actionButton actionButton--cancel"
            onClick={onClearRecording}
          >
            Cancel
          </button>
        </div>
      )}
    </div>
  );
};
