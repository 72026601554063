import * as THREE from "three";

import { Sound } from "./sound/Sound";
import { getPointOnSphere } from "./gps";
import { BufferGeometry } from "three";

const SPLINE_POINTS = 32;

const JUMP_MIN_ALTITUDE = 0.1;
const JUMP_MAX_ALTITUDE = 0.3;

const RADIANS = Math.PI / 180;
const DEGREES = 180 / Math.PI;

const FLY_IN_TIME = 100;
const FADE_OUT_TIME = 750;

export function getJumpGeometry(
    sound1: Sound,
    sound2: Sound,
    radius: number,
    lastStartsAgingAt = Date.now() - FADE_OUT_TIME
) {
    let start = sound1.getPointOnSphere(radius);
    let end = sound2.getPointOnSphere(radius);
    let altitude = Math.min(
        Math.max(start.distanceTo(end) * 0.75, JUMP_MIN_ALTITUDE),
        JUMP_MAX_ALTITUDE
    );

    let interpolate = lonLatInterpolate(
        [sound1.location.lon, sound1.location.lat],
        [sound2.location.lon, sound2.location.lat]
    );
    let midCoord1 = interpolate(0.25);
    let midCoord2 = interpolate(0.75);
    let mid1 = getPointOnSphere(midCoord1[1], midCoord1[0], radius + altitude);
    let mid2 = getPointOnSphere(midCoord2[1], midCoord2[0], radius + altitude);
    let spline = new THREE.CubicBezierCurve3(start, mid1, mid2, end);

    let vertexArray = new Float32Array(SPLINE_POINTS * 3);
    let points = spline.getPoints(SPLINE_POINTS - 1);
    for (let i = 0; i < points.length; i++) {
        vertexArray[i * 3] = points[i].x;
        vertexArray[i * 3 + 1] = points[i].y;
        vertexArray[i * 3 + 2] = points[i].z;
    }

    let geom = new THREE.BufferGeometry();
    geom.setAttribute("position", new THREE.BufferAttribute(vertexArray, 3));
    geom.setAttribute(
        "headAge",
        new THREE.BufferAttribute(new Float32Array(SPLINE_POINTS), 1)
    );
    geom.setAttribute(
        "tailAge",
        new THREE.BufferAttribute(new Float32Array(SPLINE_POINTS), 1)
    );
    geom.userData.createdAt = Date.now();
    geom.userData.startAgingAt = Math.max(
        Date.now(),
        lastStartsAgingAt + FADE_OUT_TIME
    );
    return geom;
}

function lonLatInterpolate(a: [number, number], b: [number, number]) {
    var x0 = a[0] * RADIANS,
        y0 = a[1] * RADIANS,
        x1 = b[0] * RADIANS,
        y1 = b[1] * RADIANS,
        cy0 = Math.cos(y0),
        sy0 = Math.sin(y0),
        cy1 = Math.cos(y1),
        sy1 = Math.sin(y1),
        kx0 = cy0 * Math.cos(x0),
        ky0 = cy0 * Math.sin(x0),
        kx1 = cy1 * Math.cos(x1),
        ky1 = cy1 * Math.sin(x1),
        d =
            2 *
            Math.asin(Math.sqrt(haversin(y1 - y0) + cy0 * cy1 * haversin(x1 - x0))),
        k = Math.sin(d);

    var interpolate = d
        ? function (t: number) {
            var B = Math.sin((t *= d)) / k,
                A = Math.sin(d - t) / k,
                x = A * kx0 + B * kx1,
                y = A * ky0 + B * ky1,
                z = A * sy0 + B * sy1;
            return [
                Math.atan2(y, x) * DEGREES,
                Math.atan2(z, Math.sqrt(x * x + y * y)) * DEGREES,
            ];
        }
        : function () {
            return [x0 * DEGREES, y0 * DEGREES];
        };

    return interpolate;
}

function haversin(x: number) {
    return (x = Math.sin(x / 2)) * x;
}

export function updateJumpGeometry(geom: BufferGeometry) {
    let headAge = Date.now() - geom.userData.createdAt;
    let tailAge = Date.now() - geom.userData.startAgingAt;
    if (tailAge < FADE_OUT_TIME * 2) {
        let lastPointHeadAge = headAge - FLY_IN_TIME;
        let lastPointTailAge = tailAge - FADE_OUT_TIME;
        let headAgeAttr = geom.getAttribute("headAge") as THREE.BufferAttribute;
        let tailAgeAttr = geom.getAttribute("tailAge") as THREE.BufferAttribute;
        let headAgeData = headAgeAttr.array as Float32Array;
        let tailAgeData = tailAgeAttr.array as Float32Array;
        for (let i = 0; i < SPLINE_POINTS; i++) {
            let pointHeadAge =
                headAge + (i / SPLINE_POINTS) * (lastPointHeadAge - headAge);
            let pointTailAge =
                tailAge + (i / SPLINE_POINTS) * (lastPointTailAge - tailAge);
            headAgeData[i] = pointHeadAge;
            tailAgeData[i] = pointTailAge / FADE_OUT_TIME;
        }
        headAgeAttr.needsUpdate = true;
        tailAgeAttr.needsUpdate = true;
    }
}

export const jumpShaderMaterial = new THREE.ShaderMaterial({
    vertexShader: `
        attribute float headAge;
        attribute float tailAge;
		varying float opacity;

		void main() {
            if (headAge <= 0.0) {
                opacity = 0.0;
            } else {
                opacity = 1.0 - tailAge;
            }
			gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
        }
    `,
    fragmentShader: `
        varying float opacity;
        
        void main() {
            gl_FragColor = vec4( 0.7019607843, 0.7019607843, 0.7019607843, opacity );
        }
    `,
    blending: THREE.AdditiveBlending,
    transparent: true,
    depthWrite: false
});
