import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  Suspense,
} from "react";
import { Canvas } from "react-three-fiber";
import classNames from "classnames";
import { isNumber, debounce } from "lodash";
import * as THREE from "three";

import "./App.scss";
import { Earth } from "./Earth";
import * as audio from "./sound/audio";
import * as api from "./api";

import { Sound } from "./sound/Sound";
import { AudioToggle } from "./AudioToggle";
import { ZoomControls } from "./ZoomControls";
import { Intro } from "./Intro";

import { AudioRecord } from "./AudioRecord";
import { AudioUpload } from "./AudioUpload";
import { FooterLinks } from "./FooterLinks";
import { ProgressMeter } from "./ProgressMeter";
import { DragOverlay } from "./DragOverlay";
import { SoundRecording } from "./sound/SoundRecorder";
import { AudioRecording } from "./AudioRecording";
import CameraControls from "camera-controls";
import { Camera, PerspectiveCamera, OrthographicCamera } from "three";

CameraControls.install({ THREE: THREE });

function App() {
  let [sounds, setSounds] = useState<Sound[]>([]);
  let [currentRecording, setCurrentRecording] = useState<SoundRecording>();
  let [uploadProgress, setUploadProgress] = useState<number | null>(null);
  let [uploadError, setUploadError] = useState(false);
  let [dragOver, setDragOver] = useState(false);
  let [inIntro, setInIntro] = useState(true);
  let [cameraControls, setCameraControls] = useState<CameraControls>();
  let [audioListener, setAudioListener] = useState<THREE.AudioListener>();

  const uiVisible =
    !inIntro &&
    !isNumber(uploadProgress) &&
    !uploadError &&
    !dragOver &&
    (!currentRecording || currentRecording.state !== "done");

  useEffect(() => {
    api.loadSounds().then(setSounds);
  }, []);

  let uploadFile = useCallback(
    (blob: Blob, source: "app" | "file", useDeviceLocation: boolean) => {
      setUploadProgress(0);
      let upload = api.uploadSound(blob, source, useDeviceLocation);
      upload.on("progress", (progress) => {
        setUploadProgress(progress);
      });
      upload.on("error", () => {
        setUploadError(true);
      });
      upload.on("done", () => {
        setUploadProgress(null);
        setUploadError(false);
        setCurrentRecording(undefined);
      });
    },
    []
  );
  let uploadRecording = useCallback(() => {
    uploadFile(currentRecording!.recording!, "app", true);
    setCurrentRecording(undefined);
  }, [currentRecording, uploadFile]);
  let onDragOut = useMemo(() => debounce(() => setDragOver(false), 300), []);
  let onDragOver = useCallback((evt: React.DragEvent) => {
    setDragOver(evt.dataTransfer.types.indexOf("Files") >= 0);
    onDragOut();
    evt.preventDefault();
  }, []);
  let onDrop = useCallback((evt: React.DragEvent) => {
    if (evt.dataTransfer.files.length) {
      let file = evt.dataTransfer.files[0];
      if (file.type.startsWith("audio") || file.type.startsWith("video")) {
        uploadFile(file, "file", false);
      }
    }
    evt.preventDefault();
  }, []);

  let onCanvasInit = useCallback(
    (
      camera: PerspectiveCamera | OrthographicCamera,
      canvas: HTMLCanvasElement
    ) => {
      setCameraControls(new CameraControls(camera, canvas));
      let audioListener = audio.makeAudioListener();
      camera.add(audioListener);
      setAudioListener(audioListener);
    },
    []
  );

  let zoomIn = useCallback(() => {
    cameraControls!.zoom((cameraControls as any)._camera.zoom / 2, true);
  }, [cameraControls]);

  let zoomOut = useCallback(() => {
    cameraControls!.zoom(-(cameraControls as any)._camera.zoom / 2, true);
  }, [cameraControls]);

  return (
    <div
      className="App"
      onClick={audio.init}
      onDragOver={onDragOver}
      onDrop={onDrop}
    >
      <div
        className={classNames("persistentUI", {
          isVisible: uiVisible,
        })}
      >
        <h1 className="appTitle">
          Sound of the Earth:
          <br />
          the pandemic
          <br />
          chapter
        </h1>
        <div className="audioInputs">
          <AudioUpload onUpload={(blob) => uploadFile(blob, "file", false)} />
          <AudioRecord
            currentRecording={currentRecording}
            onSetCurrentRecording={setCurrentRecording}
          />
        </div>
        {audioListener && (
          <AudioToggle
            gain={audioListener.gain}
            audioCtx={audio.audioContext}
          />
        )}
        <ZoomControls onZoomIn={zoomIn} onZoomOut={zoomOut} />
        <FooterLinks />
      </div>
      {isNumber(uploadProgress) && uploadProgress < 1 && !uploadError && (
        <ProgressMeter progressPercent={Math.round(uploadProgress * 100)} />
      )}
      <div
        className={classNames("statusMessage", {
          isVisible: uploadProgress === 1,
        })}
      >
        Thank you for contributing to this global artwork!
        <br />
        All submissions are reviewed and may take several days to post.
      </div>
      <div className={classNames("statusMessage", { isVisible: uploadError })}>
        There was an error receiving your submission.
        <br />
        Please check your file size and format and try again.
      </div>
      {currentRecording && (
        <AudioRecording
          recording={currentRecording}
          onUploadRecording={uploadRecording}
          onClearRecording={() => setCurrentRecording(undefined)}
        />
      )}
      <Intro isVisible={inIntro} onDismiss={() => setInIntro(false)} />
      <DragOverlay isVisible={dragOver} />
      <Canvas
        onCreated={(cnvs) => onCanvasInit(cnvs.camera, cnvs.gl.domElement)}
      >
        <Suspense fallback={<NoOp />}>
          <Earth
            sounds={sounds}
            cameraControls={cameraControls!}
            audioListener={audioListener!}
            isInteractive={!currentRecording && uiVisible}
          />
        </Suspense>
      </Canvas>
    </div>
  );
}

const NoOp = () => <></>;

export default App;
