import React from "react";

import "./ProgressMeter.scss";

interface ProgressMeterProps {
  progressPercent: number;
}

export const ProgressMeter: React.FC<ProgressMeterProps> = ({
  progressPercent,
}) => {
  let radius = 175;
  let stroke = 15;
  let normalisedRadius = radius - stroke * 2;
  let circumference = normalisedRadius * 2 * Math.PI;
  let strokeDashoffset =
    circumference - (progressPercent / 100) * circumference;

  return (
    <div className="progressMeter">
      <p className="progressMeterPercentText">{progressPercent}%</p>
      <svg
        className="progressMeterCircle"
        height={radius * 2}
        width={radius * 2}
      >
        <circle
          stroke="white"
          fill="transparent"
          strokeWidth={stroke}
          stroke-width={stroke}
          r={normalisedRadius}
          cx={radius}
          cy={radius}
        />
        <circle
          className="spinning"
          stroke="grey"
          fill="transparent"
          strokeWidth={stroke - 9}
          strokeDasharray={circumference + " " + circumference}
          style={{ strokeDashoffset }}
          stroke-width={stroke - 9}
          // stroke-linecap="round"
          r={normalisedRadius}
          cx={radius}
          cy={radius}
        />
      </svg>
    </div>
  );
};
