import React, { useCallback } from "react";
import classNames from "classnames";

import { SoundRecorder, SoundRecording } from "./sound/SoundRecorder";
import { getMicrophoneStream, audioContext } from "./sound/audio";

import "./AudioRecord.scss";

interface AudioRecordProperties {
  currentRecording?: SoundRecording;
  onSetCurrentRecording: (recording: SoundRecording) => void;
}
export const AudioRecord: React.FC<AudioRecordProperties> = ({
  currentRecording,
  onSetCurrentRecording,
}) => {
  let record = useCallback(() => {
    getMicrophoneStream().then((stream) => {
      let recorder = new SoundRecorder(audioContext, stream);
      recorder.start();
      recorder.on("recorded", (blob) => {
        onSetCurrentRecording({ state: "done", recording: blob, recorder });
      });
      onSetCurrentRecording({ state: "recording", recorder });
    });
  }, [onSetCurrentRecording]);

  let stop = useCallback(() => {
    currentRecording?.recorder.stop();
  }, [currentRecording]);

  return (
    <div className="audioRecord">
      <div className="audioRecordLabel">
        {currentRecording?.state === "recording" ? (
          <>Stop recording</>
        ) : (
          <>Record audio</>
        )}
      </div>
      <button
        className={classNames("audioRecordButton", {
          isRecording: currentRecording?.state === "recording",
        })}
        onClick={currentRecording?.state === "recording" ? stop : record}
      >
        <span className="sr-only">Record</span>
        <svg viewBox="0 0 73 73">
          <circle fill="gray" cx="36.5" cy="36.5" r="36.5" />
          <circle className="redCircle" fill="red" cx="36.5" cy="36.5" r="33" />
          <rect
            fill="red"
            x="16.72"
            y="16.1"
            width="40.2"
            height="40.2"
            rx="9.85"
          />
        </svg>
      </button>
    </div>
  );
};
