import React, { useState } from "react";
import { AboutContent } from "./AboutContent";

import "./FooterLinks.scss";
import DMALogoImg from "./images/dallas-museum-art-logo.png";
import TexasInstrumentsLogoImg from "./images/texas-instruments-logo.png";

export const FooterLinks: React.FC = () => {
  let [isVisible, setisVisible] = useState(false);

  return (
    <>
      <nav className="footerLinks">
        <div className="logos">
          <a
            className="footerLink footerLink--logo"
            href="//virtual.dma.org"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className="footerLink--logoImg"
              src={DMALogoImg}
              alt="Dallas Museum of Art"
            />
          </a>
          <span className="footerImage footerImage--texasInstruments">
            <img
              className="footerImage--texasInstrumentsImg"
              src={TexasInstrumentsLogoImg}
              alt="Texas Instruments"
            />
          </span>
        </div>
        <button
          onClick={() => setisVisible(isVisible ? false : true)}
          className="footerLink footerLink--about"
        >
          About
        </button>
        <a
          className="footerLink footerLink--privacy"
          href="//dma.org/privacy"
          target="_blank"
          rel="noopener noreferrer"
        >
          Privacy Policy
        </a>
        <a
          href="//dma.org/terms-and-conditions"
          className="footerLink footerLink--terms"
          target="_blank"
          rel="noopener noreferrer"
        >
          Terms &amp; Conditions
        </a>
      </nav>
      <AboutContent isVisible={isVisible} onSetVisible={setisVisible} />
    </>
  );
};
